import styled from 'styled-components';

export const TitleText = styled.h2`
  color: ${({ theme }) => theme.color.text.primary};
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 0;

  @media screen and (min-width: ${({ theme }) => theme.layout.sm}px) {
    font-size: 50px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 60px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    font-size: 75px;
  }
`;

export const MainTitleText = styled.h1`
  color: ${({ theme }) => theme.color.text.primary};
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  margin-top: 50px;
  line-height: 100px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 80px;
  }
`;

export const AccentMainTitleText = styled.h1`
  text-align: center;
  font-size: 40px;
  line-height: 76px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.text.accent};
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 0;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 60px;
    margin-top: 100px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    margin-top: 150px;
    font-size: 80px;
  }
`;
