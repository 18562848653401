import styled from 'styled-components';

export const Separator = styled.div`
  margin-bottom: 100px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  min-height: 80vh;
  a:link {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
  a:hover {
    opacity: 0.6;
  }
  a:visited {
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.background.transparent};
    text-decoration: none;
  }
`;
