import Footer from 'components/CommonComponents/Footer';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import { MainTitleText, TitleText } from 'components/CommonComponents/Titles/styled';
import { MainContainer } from 'components/CommonComponents/Wrappers/styled';
import { Container, Separator } from 'components/Page404/styled';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import SEO from '../components/CommonComponents/SEO';

const pageQuery = graphql`
  {
    notfoundYaml {
      text {
        cs
      }
      header {
        cs
      }
      linkBackText {
        cs
      }
    }
  }
`;

const NotFoundPage = () => {
  const data = useStaticQuery(pageQuery);

  const notFoundData = data.notfoundYaml;
  const locale = 'cs';

  return (
    <>
      <SEO title="404" />
      <GlobalStyle />

      <NavigationBar />
      <MainContainer>
        <Container>
          <MainTitleText>{notFoundData.header[locale]}</MainTitleText>
          <TitleText>{notFoundData.text[locale]}</TitleText>
          <Separator />
          <Link to={'/'}>{notFoundData.linkBackText[locale]}</Link>
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

export default NotFoundPage;
